





































































import {Component, Prop, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import {IKeyCloakUser, IRegion} from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class RegionUserData extends BaseUserLink {
    private regionBase: any = [];
    private regionList: any[] = [];
    // private regionFilteredList: any[] = [];
    @Prop({default: "45"}) private oblRegion: string | undefined;
    private selectedUserRegionList: IRegion[] = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';

    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            // await this.saveUserregionData(oldValue);
            // this.selectedUser.regionList = [...this.selectedUserRegionList];
            this.selectedUserRegionList = [];
            this.regionList = [...this.regionBase];
            this.regionList.sort((a, b) => a.code - b.code);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterRegionList() {
        const res = [];
        for (const item of this.regionList) {
            if (item.code.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toUpperCase().toString().includes(this.leftFilter.toUpperCase())) {
                res.push(item);
            }
        }
        return res;
    }

    private get rightFilterRegionList() {
        const res = [];
        for (const item of this.selectedUserRegionList) {
            if (item.code.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadFuncGroup() {
        try {
            const response = await fetch('/api-py/get-regions-by-obl/' + this.oblRegion);
            this.regionBase = await response.json() as IRegion;
            this.regionList = [...this.regionBase];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            // if (this.selectedUser.regionList) {
            //     this.selectedUserRegionList = [...this.selectedUser.regionList];
            for (const item of this.selectedUserRegionList) {
                for (const it of this.regionList) {
                    if (it.code === item.code) {
                        const ind = this.regionList.indexOf(it);
                        this.regionList.splice(ind, 1);
                    }
                }
            }
            // }
        }
    }
    removeAllRegion() {
        for (const regionListElement of this.selectedUserRegionList) {
            this.regionList.push(regionListElement)
        }
        this.regionList.sort((a, b) => a.id - b.id);
        this.selectedUserRegionList = []
        this.saveUserRegionData(this.selectedUser);
    }

    addAllRegion() {
        for (const regionListElement of this.regionList) {
            if (!this.selectedUserRegionList.find(value => value.id === regionListElement.id)) this.selectedUserRegionList.push(regionListElement)
        }
        this.selectedUserRegionList.sort((a, b) => a.id - b.id);
        this.regionList = []
        this.saveUserRegionData(this.selectedUser);
    }
    private addRegionToSelectedUser(region: IRegion, save: boolean) {
        if (this.selectedUserRegionList.indexOf(region) < 0) {
            this.selectedUserRegionList.push(region);
            const ind = this.regionList.indexOf(region);
            this.regionList.splice(ind, 1);
        }
        this.selectedUserRegionList.sort((a, b) => a.id - b.id);
        // this.selectedUser.regionList = [...this.selectedUserRegionList];
        if (save) {
            this.saveUserRegionData(this.selectedUser);
        }
    }

    private removeRegionFromSelectedUser(region: IRegion, save: boolean) {
        const ind = this.selectedUserRegionList.indexOf(region);
        this.selectedUserRegionList.splice(ind, 1);
        this.regionList.push(region);
        this.regionList.sort((a, b) => a.id - b.id);
        if (save) {
            this.saveUserRegionData(this.selectedUser);
        }
    }

    private async saveUserRegionData(user: IKeyCloakUser) {
        const regionList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserRegionList) {
            const d = {
                region: item.code
            };
            regionList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                regionList: regionList
            };
            try {
                await fetch('/api-py/save-user-region', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.selectedUserRegionList = [];
                // user.regionList = [];
                const response = await fetch('/api-py/user-region/' + user?.id);
                const data = await response.json();
                for (const item of data) {
                    for (const t of this.regionList) {
                        if (item.region === t.code) {
                            this.addRegionToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных регионов по : ' + user.id, error.toString());
            }
    }

    public async mounted() {
        await this.loadFuncGroup();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }
}
