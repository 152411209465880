




























import {delay} from "lodash";
import {Vue, Prop, Component, Watch} from "vue-property-decorator";
import {IModule, IUserOperations} from "../user-management-int";
import VueElementLoading from 'vue-element-loading';
import BaseUserLink from "../base-user-link";


@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class MultyCheck extends BaseUserLink {
    @Prop() private module!: IModule;
    @Prop() private readonly operations!: IUserOperations[];

    private inner: string[] = [...(this.module.operations || [])];
    private saving = false;

    async onPropertyChanged() {
        const scope = this;
        scope.saving = true;
        this.save().then(response => {
            if (response.status >= 200 && response.status < 300) {
                scope.module.operations = [...scope.inner];
            } else {
                return Promise.reject(new Error(response.statusText))
            }
        }).catch((error: Error) => {
            this.makeToast(
                "danger",
                "Ошибка сохранения полномочий: " + this.module.name_ru,
                error.toString()
            );
            scope.inner = [...(scope.module.operations || [])];
        }).then(any => {
            scope.saving = false
        });
    }

    private getPlaceholder() {
        return this.inner.length + " выбрано";
    }

    async save() {
        const data = (JSON.parse(JSON.stringify(this.module)));
        data.id = data.baseId;
        data.operations = this.inner;
        return await fetch("/api/um/module/link/operations/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(data),
        });
    }
}
